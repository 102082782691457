import cn from "classnames";
import { graphql } from "gatsby";
import parse from "html-react-parser";

import SectionTitle from "../../molecules/section-title/SectionTitle";

const CostsCovered = ({ data }) => {
  return (
    <>
      {data.map(({ id, title, subtitle, areCostsCovered, items }) => {
        return (
          <section
            className={cn("or-costs", `or-costs--${areCostsCovered ? "covered" : "not-covered"}`)}
            key={id}
          >
            <SectionTitle title={parse(title)} subtitle={subtitle} />
            <ul className={`checklist checklist--${areCostsCovered ? "positive" : "negative"}`}>
              {items.map((item) => (
                <li key={item}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </section>
        );
      })}
    </>
  );
};

export const query = graphql`
  fragment CostsFragment on ContentfulCostsList {
    id
    title
    subtitle
    areCostsCovered
    items
  }
`;

export default CostsCovered;
