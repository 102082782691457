import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/400-italic.css";
import "@fontsource/nunito/700.css";

import cn from "classnames";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

import BackgroundShape from "../atoms/background-shape/BackgroundShape";
import Icons from "../core/ui/images/icomoon/symbol-defs.svg";
import Footer from "../core/ui/organisms/footer/Footer";
import GoogleReviews from "../organisms/./google-reviews/GoogleReviews";
import AppReimbursement from "../organisms/app-reimbursement/app_reimbursement";
import Costs from "../organisms/costs/Costs";
import Faqs from "../organisms/faqs/Faqs";
import Hero from "../organisms/hero/Hero";
import HowWorks from "../organisms/how-works/HowWorks";
import KspList from "../organisms/ksp-list/KspList";
import LandingBottomCta from "../organisms/landing-bottom-cta/LandingBottomCta";
import Meta from "../organisms/meta/meta";
import Navigation from "../organisms/navigation/Navigation";
import PageWrapper from "../organisms/page-wrapper/PageWrapper";
import Reimbursement from "../organisms/reimbursement/reimbursement";
import withLandingWrapper from "../organisms/with_landing_wrapper";
import useAxeptioScriptLoader from "../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../utils/locale-configuration-utils";
import * as styles from "./landing/Landing.module.scss";

const Landing = ({ data }) => {
  const { i18n } = useI18next();

  const {
    slug,
    locale,
    metaTitle,
    metaDescription,
    hero,
    benefits,
    reimbursement,
    costs,
    howWorks,
    appReimbursement,
    faqs,
    reviewsWidget,
    landingBottomCta,
    footer,
  } = data.contentfulMarketingLandingLocal;

  const countryCodeIso = getCountryByLocale(locale);

  void i18n.changeLanguage(locale);
  useAxeptioScriptLoader(locale);

  return (
    <>
      <Meta
        title={metaTitle}
        description={metaDescription}
        slug={slug}
        countryCodeIso={countryCodeIso}
        lang={locale}
        bodyClass={cn(styles.landing, styles.marketingLanding)}
      />
      <PageWrapper>
        <Navigation countryCodeIso={countryCodeIso} />

        <main className={cn(styles.main, "or-main")}>
          <Hero data={hero} countryCodeIso={countryCodeIso} adoptionClassName={styles.hero} />
          {benefits && (
            <KspList data={benefits} variantName="benefits" adoptionClassName={styles.kspList} />
          )}
          {reimbursement && (
            <Reimbursement data={reimbursement} adoptionClassName={styles.reimbursement} />
          )}
          {costs && <Costs data={costs} />}
          {howWorks && <HowWorks data={howWorks} adoptionClassName={styles.howWorks} />}
          {appReimbursement && (
            <AppReimbursement data={appReimbursement} adoptionClassName={styles.appReimbursement} />
          )}
          {faqs && <Faqs data={faqs} adoptionClassName={styles.faqs} />}
          {reviewsWidget && <GoogleReviews data={reviewsWidget} />}
          {landingBottomCta && (
            <LandingBottomCta
              data={landingBottomCta}
              countryCodeIso={countryCodeIso}
              adoptionClassName={styles.homeBottomCta}
            />
          )}
        </main>

        <Footer data={footer} countryCode={countryCodeIso} adoptionClassName={styles.footer} />

        <BackgroundShape />
        <Icons />
      </PageWrapper>
    </>
  );
};

export default withLandingWrapper(Landing);

export const query = graphql`
  query MarketingLandingQuery($id: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulMarketingLandingLocal(id: { eq: $id }) {
      slug
      locale: node_locale
      metaTitle
      metaDescription
      hero {
        ...HeroFragment
      }
      benefits {
        ...KspFragment
      }
      reimbursement {
        ...ReimbursementFragment
      }
      costs {
        ...CostsFragment
      }
      howWorks {
        ...HowWorksFragment
      }
      appReimbursement {
        ...AppReimbursementFragment
      }
      faqs {
        ...FaqsFragment
      }
      reviewsWidget {
        ...reviewsWidgetFragment
      }
      landingBottomCta {
        ...LandingBottomCtaFragment
      }
      footer {
        ...FooterFragment
      }
    }
  }
`;
