import cn from "classnames";
import { graphql } from "gatsby";
import parse from "html-react-parser";

import SectionTitle from "../../molecules/section-title/SectionTitle";

const HowWorks = ({ data: { title, subtitle, steps }, adoptionClassName }) => {
  return (
    <section className={cn(adoptionClassName, "or-how-works")}>
      <SectionTitle title={parse(title)} subtitle={subtitle} />
      <ol className="step-list">
        {steps.map(({ id, title, content }) => (
          <li key={id}>
            <h3>{title}</h3>
            <p>{content}</p>
          </li>
        ))}
      </ol>
    </section>
  );
};

export const query = graphql`
  fragment HowWorksFragment on ContentfulHowWorks {
    title
    subtitle
    steps {
      id
      title
      content
    }
  }
`;

export default HowWorks;
